<template>
  <div>
    <b-row>
      <b-col cols="2" sm="6" md="6" lg="4">
        <b-button @click.prevent="openModal" class="btn-filter mr-2">
          <span class="d-none d-sm-flex align-items-center">
            <span class="mr-2">Select Items </span>
            <font-awesome-icon icon="chevron-right" class="pointer" />
          </span>
          <font-awesome-icon icon="chevron-right" class="d-sm-none" />
        </b-button>
        <span class="text-black text-nowrap">Selected {{ rows }} list</span>
      </b-col>
      <b-col cols="10" sm="6" md="6" lg="4" offset-lg="4">
        <b-input-group class="mb-2">
          <b-form-input
            type="text"
            id="header-search-bar"
            class="search-bar"
            @keyup.enter="handleSearch"
            placeholder="Search Product Name"
     
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append> </b-input-group
      ></b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          responsive
          hover
          :fields="fields"
          striped
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(name)="{ item }">
            <div class="font-bold">{{ item.name }}</div>

            <div v-html="item.description || '-'"></div>
          </template>
          <template v-slot:cell(imageUrl)="data">
            <div class="position-relative picture-text pic-table">
              <img
                v-if="data.item.imageUrl"
                class="product-image pt-0"
                :src="data.item.imageUrl"
                alt="product image"
              />
              <div v-else>
                <img
                  class="product-image pt-0"
                  :src="default_image"
                  alt="default Image"
                  srcset=""
                />
              </div>
            </div>
          </template>
          <template v-slot:cell(price)="{ item }">
            <div class="price-baht">
              {{ item.price | numeral("0,.00") }}
            </div>
          </template>
          <template v-slot:cell(sap_price)="{ item }">
            <InputText
              textFloat=""
              placeholder=""
              type="number"
              v-model="
                productSelectList[
                  productSelectList.findIndex((el) => el.product_id == item.id)
                ].price
              "
              @onKeyup="handleMaxPrice(item, $event)"
              :disabled="
                freezeProductList.filter((el) => el.product_id == item.id)
                  .length >= 1
              "
              name="round_day"
              :useDot="false"
            />
            <!-- :v="v.form.add_product.$iter[index]"
              :isValidate="v.form.add_product.$iter[index]" -->
            <!-- :v="v.form.add_product.$each[].quantity" -->
          </template>
          <template v-slot:cell(delete)="item">
            <b-button
              variant="link"
              @click="handleDeleteProduct(item)"
              class="text-gray"
            >
              <font-awesome-icon icon="trash-alt" class="ft-14 ml-2"
            /></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />

    <ModalSelectProduct
      :useBy="useBy"
      :isSap="true"
      :freezeProductList="freezeProductList"
      ref="modalSelectProduct"
      @submit="selectedProduct"
    />
  </div>
</template>

<script>
import ModalSelectProduct from "@/components/ModalSelectProduct";
export default {
  components: { ModalSelectProduct },
  props: {
    filter: {
      required: false,
      defualt: {
        search: "",
        page: 1,
        take: 5,
      },
      type: Object,
    },
    rows: { required: false, default: 0, type: Number },
    fields: { required: false, type: Array },
    items: { required: false, type: Array },
    form: { required: false, type: Object },
    isBusy: { required: false, type: Boolean },
    useBy: { required: true },
    freezeProductList: { required: false, type: Array },
  },
  data() {
    return {
      productSelectList: [...this.freezeProductList],
    };
  },
  methods: {
    openModal() {
      this.$refs.modalSelectProduct.show(this.productSelectList, "sap");
    },

    pagination(val) {
      this.$emit("pagination", val);
    },
    handleChangeTake(val) {
      this.$emit("handleChangeTake", val);
    },
    handleSearch() {
      this.$emit("handleSearch");
    },
    handleMaxPrice(items, e) {
      const index = this.productSelectList.findIndex(
        (el) => el.product_id == items.id
      );
      this.$nextTick(() => {
        if (Number(this.productSelectList[index].price) > items.price) {
          return (this.productSelectList[index].price = items.price);
        }
      });
    },
    handleDeleteProduct({ item, index }) {
      if (!this.form.delete_product_id) this.form.delete_product_id = [];
      this.form.delete_product_id.push(item.id);
      this.form.add_product_id.splice(
        this.form.add_product_id.indexOf(item.id),
        1
      );
      // this.items.splice(index, 1);
      this.$emit("syncProductSelect", this.form);
    },

    selectedProduct(product) {
      let originalProduct =
        this.form.sap_product.length == 0 ? [] : this.form.sap_product;
      var addProduct = product.filter((id) => !originalProduct.includes(id));

      for (const productId of addProduct) {
        const findProductList = this.productSelectList.findIndex(
          (el) => el.product_id == productId
        );
        if (findProductList == -1) {
          this.productSelectList.push({
            product_id: productId,
            price: 0,
          });
        }
      }

      this.form.add_product_id = addProduct;

      this.$emit("syncProductSelect", this.form, this.productSelectList);
    },
  },
};
</script>

<style>
.price::before {
  content: "฿ ";
}
</style>
