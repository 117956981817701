<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <h1 class="mr-sm-4 header-tablepage">PROMOTION DETAIL</h1>
        </div>
        <div class="title-tabs mt-3">Set up Promotion</div>
        <b-container class="no-gutters bg-white py-2">
          <div class="py-3">
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Promotion Name"
                  placeholder="Promotion Name"
                  type="text"
                  name="promotions_name"
                  isRequired
                  :isValidate="$v.form.name.$error"
                  :v="$v.form.name"
                  v-model="form.name"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Promotions Ref."
                  placeholder="Promotions Ref."
                  type="text"
                  name="promotions-ref"
                  isRequired
                  :isValidate="$v.form.promotion_ref.$error"
                  :v="$v.form.promotion_ref"
                  v-model="form.promotion_ref"
                />
              </b-col>

              <div class="break-normal"></div>
              <b-col lg="6" md="6" sm="12" xs="12" class="mb-md-0 mb-3">
                <label class="label-text">
                  Valid From <span class="text-danger">*</span></label
                >

                <div
                  :class="[
                    'input-container',
                    { error: $v.form.start_datetime.$error },
                  ]"
                >
                  <datetime
                    type="datetime"
                    v-model="form.start_datetime"
                    placeholder="Please Select Date"
                    format="dd/MM/yyyy (HH:mm)"
                    value-zone="Asia/Bangkok"
                    @input="handleMinDate"
                    ref="start_datetime"
                  >
                  </datetime>
                  <div
                    :class="
                      'icon-primary text-right ' + id == 0
                        ? ''
                        : 'cursor-default'
                    "
                    @click="$refs.start_datetime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="
                        id == 0
                          ? 'pointer color-primary'
                          : 'cursor-default color-primary'
                      "
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <div v-if="$v.form.start_datetime.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.start_datetime.required == false"
                    >Please select date time.</span
                  >
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <label class="label-text">
                  Valid To <span class="text-danger">*</span></label
                >
                <div
                  :class="[
                    'input-container',
                    { error: $v.form.end_datetime.$error },
                  ]"
                >
                  <datetime
                    type="datetime"
                    v-model="form.end_datetime"
                    placeholder="Please Select Date"
                    format="dd/MM/yyyy (HH:mm)"
                    value-zone="Asia/Bangkok"
                    :min-datetime="form.start_datetime"
                    ref="end_datetime"
                  >
                  </datetime>
                  <div
                    :class="
                      'icon-primary text-right ' + id == 0
                        ? ''
                        : 'cursor-default'
                    "
                    @click="$refs.end_datetime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="
                        id == 0
                          ? 'pointer color-primary'
                          : 'cursor-default color-primary'
                      "
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <div v-if="$v.form.end_datetime.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.end_datetime.required == false"
                    >Please select date time.</span
                  >
                </div>
              </b-col>
            </b-row>
            <hr />
          </div>
          <div class="panelborder">
            <div class="title-tabs">
              Promotions Items <span class="text-danger">*</span>
            </div>
            <div class="px-3 py-2">
              <TableProductSelect
                ref="productPromotionsSelect"
                useBy="promotions"
                :filter="filter"
                :fields="fields"
                :items="items"
                :rows="rows"
                :freezeProductList="freezeProductList"
                :form="form"
                @syncProductSelect="syncProductSelect"
                @pagination="pagination"
                @handleChangeTake="handleChangeTake"
                @handleSearch="handleSearch"
                :v="$v.form.add_product"
              />

              <div class="text-error" v-if="$v.form.add_product.$error">
                <span
                  v-if="
                    this.form.add_product.length == 0 &&
                    this.form.sap_product.lenght == 0
                  "
                  >Please select at least 1 product.</span
                >
                <span v-else>Please Set Promotion Price</span>
              </div>
              <div class="text-error" v-if="$v.form.sap_product.$error">
                <span v-if="this.form.add_product.length == 0"
                  >Please select at least 1 product.</span
                >
                <!-- <span v-else>Please Set Promotion Price</span> -->
              </div>
            </div>
            <div name="branch-select" class="px-3 py-2">
              <div class="mb-2 main-label">
                Participating Branches <span class="text-danger">*</span>
              </div>

              <ModalSelectBranch
                ref="ModalSelectBranch"
                @selectBranch="selectBranch"
                :list="branchList"
                :form="form"
                id="1"
                :isBranchDisable="isDisabled"
                text="Select Branch"
              />
              <div class="text-error" v-if="$v.form.branch_id.$error">
                Please select at least 1 branch.
              </div>
            </div>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>

        <FooterAction @submit="saveForm()" routePath="/promotions" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  requiredIf,
  minValue,
} from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import TableProductSelect from "@/components/promotions/TableSapProduct";
import ModalSelectBranch from "@/components/promotions/ModalSelectBranch";
export default {
  name: "Detail",
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    TableProductSelect,
    ModalSelectBranch,
  },
  validations() {
    return {
      form: {
        name: { required },
        promotion_ref: { required },
        start_datetime: { required },
        end_datetime: { required },
        branch_id: {
          required: requiredIf(function () {
            return (
              this.form.add_branch_id.length == 0 &&
              this.form.branch_id.length == 0
            );
          }),
        },
        sap_product: {
          required: requiredIf(function () {
            return this.items.length == 0 && this.form.add_product.length == 0;
          }),
        },
        add_product: {
          $each: {
            price: {
              required,
              minValue: minValue(0),
            },
          },
        },
      },
    };
  },

  computed: {
    isEdit() {
      return this.$route.params.id == 0 ? false : true;
    },
    isDisabled() {
      return (
        this.$route.params.id != 0 &&
        this.$moment().diff(
          this.$moment(this.form.start_datetime || ""),
          "days"
        ) >= 0
      );
    },
  },
  watch: {},
  data() {
    return {
      isLoading: false,
      branchList: [],
      percentOrbath: false,
      freezeProductList: [],
      form: {
        id: 0,
        name: null,
        start_datetime: "",
        end_datetime: "",
        promotion_ref: null,
        branch_group_id: 0,
        status: 0,
        branch_id: [],
        add_branch_id: [],
        delete_branch_id: [],
        product_id: [],
        add_product: [],
        sap_product: [],
      },
      filter: {
        search: "",
        page: 1,
        take: 5,
      },

      id: this.$route.params.id,
      fields: [
        {
          key: "imageUrl",
          label: "Photo",
        },
        {
          key: "name",
          label: "Product",
          tdClass: "text-left",
        },
        {
          key: "ah_no",
          label: "SKU",
        },
        {
          key: "Price",
          label: "Price",
        },
        {
          key: "sap_price",
          label: "Promotion Price",
        },
      ],
      items: [],
      rows: 0,
      isBusy: false,
      productSelected: [],
      delete_branch_id: [],
      productForm: {
        pageNo: 1,
        // start: 0,
        length: 5,
        promotion_id: this.$route.params.id,
        add_product_id: [],
        delete_product_id: [],
      },
    };
  },
  created() {
    this.getBranchList();

    if (this.isEdit) this.getData();
    // this.$showLoading();
  },
  destroyed() {},
  methods: {
    async getData() {
      const result = await this.axios(`/promotion/sappromotion/${this.id}`);
      this.form = result.data.detail;

      this.freezeProductList = result.data.detail.sap_product;
      this.getProductList();
    },

    async syncProductSelect(productList) {
      this.productForm.add_product_id = productList.add_product_id;
      this.productForm.delete_product_id = productList.delete_product_id;
      this.getProductList();
    },
    async getProductList() {
      let payload = { ...this.productForm };

      payload.pageNo = this.filter.page;
      payload.search = this.filter.search;
      payload.length = this.filter.take;

      const result = await this.axios.post(
        `/promotion/ProductBySapPromotion`,
        payload
      );

      for (const product of result.data.detail.product_list) {
        const index =
          this.$refs.productPromotionsSelect.productSelectList.findIndex(
            (el) => el.product_id == product.id
          );
        if (index == -1) {
          this.$refs.productPromotionsSelect.productSelectList.push({
            product_id: product.id,
            price: product.sap_price,
          });
        }
      }

      this.items = result.data.detail.product_list;

      this.rows = result.data.detail.total_product;
    },
    async getBranchList() {
      const result = await this.axios(`/branch/group`);
      var branchSelect = [];
      for (let branch of result.data.detail) {
        branch.selected_count = 0;
        for (const check of branch.branchList) {
          if (this.form.branch_id.includes(check.id)) {
            branch.selected_count += 1;
            check.branch_group = branch.name;
            check.is_check = true;
            if (
              this.$route.params.id != 0 &&
              this.$moment().diff(
                this.$moment(this.form.start_datetime || ""),
                "days"
              ) >= 0
            ) {
              check.is_disabled = true;
            }
            branchSelect.push(check);
          }
        }
      }
      // this.rows = branchSelect.length;
      this.branchList = result.data.detail;
    },
    handleSearch() {
      this.getProductList();
    },
    handleMinDate() {
      let diff = this.$moment(this.form.end_datetime).diff(
        this.$moment(this.form.start_datetime),
        "min"
      );

      if (diff < 0) this.form.end_datetime = "";
    },
    pagination(val) {
      this.filter.page = val;
      this.getProductList();
    },
    handleChangeTake() {
      this.getProductList();
    },

    selectBranch(val) {
      const addID = val.filter((el) => !this.form.branch_id.includes(el));
      const deleteID = this.form.branch_id.filter((i) => !val.includes(i));

      this.form.add_branch_id = addID;
      // this.form.branch_id = val;
      this.form.delete_branch_id = deleteID;
    },

    async saveForm() {
      this.form.add_product =
        this.$refs.productPromotionsSelect.productSelectList.filter(
          (el) =>
            !this.form.sap_product
              .map(({ product_id }) => product_id)
              .includes(el.product_id)
        );

      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      const url = `/promotion/sappromotion`;
      this.$bus.$emit("showLoading");
      const requestPath = this.isEdit
        ? this.axios.put(url, this.form)
        : this.axios.post(url, this.form);
      const result = await requestPath;
      if (result.data.result) {
        this.successAlert().then(() => this.$router.push("/promotions"));
      } else {
        this.errorAlert(result.data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
::v-deep h2#swal2-title.swal2-title {
  font-size: 24px !important;
}
::v-deep .price-baht {
  &::before {
    content: "฿";
  }
}
::v-deep .font-bold {
  font-weight: bold;
}
.select-branch {
  display: flex;
  width: max-content;
  align-items: center;
}
.panel-select-branch {
  gap: 10px;
  align-items: center;
  display: flex;
}
.panelborder {
  border: 1px solid #d8dbe0;
}
</style>
